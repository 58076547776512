<script>
    import Football from "@/services/Football";
    import vue2Dropzone from "vue2-dropzone";
    import "vue2-dropzone/dist/vue2Dropzone.min.css";
    import _ from 'underscore';
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';

    export default {
        components:{
            vueDropzone: vue2Dropzone, VueBootstrapTypeahead ,
        },
        props: {
            sportTeamData: {
                type: [Array, Object],
                default: () => ({})
            },
        },
        data() {
            return {
                csrf_token: localStorage.getItem("csrf_token"),
                showModal: false,
                tryingToEdit: false,
                status_new: 0,
                show_image: false,
                show_team_logo_button : false ,
                file_e: '',
                home_shirt: '',
                away_shirt: '',
                teams : [] ,
                photoDeleted: false,
                photoDeletedAway: false,
                photoDeletedHome: false,
                errorMsg : '' ,
                team_name_key : 0 ,
                secondModalVisible : false ,
                relatedTeams: [] ,
                showAddRelatedTeamModal : false ,
                loading : null ,
                TeamsData : [] ,
                isLoading: false,
                selectedTeams: [],
                team_name : '' ,
                filterInput: {
                    team_name: ''
                },
                dropzoneOptions_primary: {
                    url: '/',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 200,
                    thumbnailMethod: 'contain',
                    maxFilesize: 5,
                    acceptedFiles: 'image/*',
                    maxFiles: 3,
                },
                dropzoneOptions_home: {
                    url: '/',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 200,
                    thumbnailMethod: 'contain',
                    maxFilesize: 5,
                    acceptedFiles: 'image/*',
                    maxFiles: 3,
                },
                dropzoneOptions_away: {
                    url: '/',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 200,
                    thumbnailMethod: 'contain',
                    maxFilesize: 5,
                    acceptedFiles: 'image/*',
                    maxFiles: 3,
                },
            };
        },

        watch: {
            'filterInput.team_name': _.debounce(function (search) {
                this.getSportTeams(search, 'team_name')
            }, 500),
        } ,
        methods: {
            refreshData() {
                this.$emit('onRefresh');
            },
            async getSportTeams(query, inputTypeName) {
                if (query.trim().length > 0) {
                    const filters = inputTypeName === 'team_name' ? `team_name=${query}&related_teams=1` : `${query}&related_teams=1`;
                    const response = await Football.getSportTeams(filters);
                    const rawData = response.data.teams;

                        this.TeamsData = rawData.map(item => ({
                            text: item.team_name_new,
                            team_id: item.team_id,
                            provider: item.provider,
                            team_type: item.team_type
                        }));

                } else {
                    this.TeamsData = [];
                }
            } ,

            addSelectedTeam(team) {
                const exists = this.selectedTeams.some(selected => selected.text === team.text);
                if (!exists) {
                    const selectedTeamData = {
                        text: team.text,
                        team_id: team.team_id,
                        provider: team.provider,
                        team_type: team.team_type
                    };
                    this.selectedTeams.push(selectedTeamData);
                }
                this.filterInput.team_name = '';
                this.team_name_key++;
            } ,

            removeSelectedTeam(team) {

            this.selectedTeams = this.selectedTeams.filter(selected => selected.text !== team.text);

            },


            removeRelatedTeams(teamId, index) {
                this.relatedTeams.splice(index, 1);
                this.isLoading = true;

                Football.removeRelatedTeams(teamId)
                    .then(response => {
                        this.successmsg("Team successfully deleted");
                        this.getRelatedTeams();
                        this.isLoading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.failedmsg("Failed to delete team");
                        this.isLoading = false;
                    });
            },

            async updateSportTeam() {
                this.tryingToEdit = true;
                const id = this.sportTeamData.team_id;
                const relatedTeamIds = this.relatedTeams.map(team => team.team_id);

                const data = {
                    team_name_overwrite: this.sportTeamData.team_name,
                    team_code_overwrite: this.sportTeamData.team_name_short,
                    team_code: this.sportTeamData.team_code_new,
                    team_type: this.sportTeamData.team_type,
                    home_shirt: this.home_shirt,
                    away_shirt: this.away_shirt,
                    team_logo: this.file_e ,
                    team_related_id: relatedTeamIds ,
                };
                try {
                    await Football.updateSportTeam(id, data,{
                    }).then((response) => {
                        this.successmsg("Change successfully saved");
                        this.refreshData()
                        this.closeModal();
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Failed to save changes")
                    }).finally(() => {
                        this.tryingToEdit = false;
                    })
                }
                catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }
            },
            async getSportTeams1() {
                try {
                    var filters = 'team_sport=football';
                    const response1 = await Football.getSportTeams(filters);
                    const teams = response1.data.teams;
                    this.teams = teams;
                } catch (error) {
                    console.log('');
                }
            },
            async getRelatedTeams() {
                try {
                    var filters = `team_sport=football&team_id=${this.sportTeamData.team_id}`;
                    const response = await Football.getRelatedTeams(filters);
                    this.relatedTeams = response.data;

                } catch (error) {
                    console.log('Error fetching related teams:', error);
                }
            },
            callAddRelatedTeamsModal() {
                this.getSportTeams1();
                this.showAddRelatedTeamModal = true;
            },

            addRelatedTeam() {

                this.isLoading = true;

                if (this.selectedTeams.length === 0) {
                    this.failedmsg("No teams selected to add.");
                    return;
                }


                if(this.relatedTeams != '' ) {
                    this.relatedTeams.forEach(team => {
                        this.id =(team.id);
                    });
                } else {
                    this.id = ''
                }


                const teamDataList = [];

                const mainClubData = {
                    id: this.id,
                    team_id: this.sportTeamData.team_id,
                    provider: this.sportTeamData.provider,
                };

                teamDataList.push(mainClubData);

                this.selectedTeams.forEach(team => {
                    const relatedTeamData = {
                        id: this.id,
                        team_id: team.team_id,
                        provider: team.provider,
                    };

                    teamDataList.push(relatedTeamData);
                });


                Football.addRelatedTeam(teamDataList)
                    .then(response => {
                        this.successmsg("Teams successfully added");
                        this.refreshData();
                        this.selectedTeams = [];
                        this.secondModalVisible = false;
                        this.getRelatedTeams();
                        this.isLoading = false;
                    })
                    .catch(error => {
                        this.failedmsg("Failed to add teams");
                    });

            } ,

            onSecondModalClose() {
                this.relatedTeams = '';
                this.getRelatedTeams();
            },

            closeModal() {
                this.showModal = false;
                this.photoDeleted = false;
                this.photoDeletedAway = false;
                this.photoDeletedHome = false;

                this.file_e = '';
                this.home_shirt =  '';
                this.away_shirt =  '';
                this.$emit("onCancel");
                this.$bvModal.hide("edit_sport_team");
                this.refreshData();
            },
            handleModalClose() {
                this.refreshData();
                this.photoDeleted = false;
                this.photoDeletedAway = false;
                this.photoDeletedHome = false;
                this.file_e = '';
                this.home_shirt =  '';
                this.away_shirt =  '';
                this.relatedTeams = '' ;
            },
            deleteImage() {
                this.photoDeleted = true;
                this.file_e = '1';
                this.sportTeamData.team_logo = '';
            },
            deleteImageHome() {
                this.photoDeletedHome = true;
                this.home_shirt = '1';
                this.sportTeamData.home_shirt = '';
            },
            deleteImageAway() {
                this.photoDeletedAway = true;
                this.away_shirt = '1';
                this.sportTeamData.away_shirt = '';
            },
            selectFile(files) {
                setTimeout(() => {
                    let queuedFiles = this.$refs.myVueDropzone_primary.getQueuedFiles();

                    if (queuedFiles.length > 1) {
                        this.failedmsg('You can only have one primary image');

                        files.forEach((value) => {
                            this.$refs.myVueDropzone_primary.removeFile(value);
                        });
                    }
                    this.file_e = queuedFiles[0];
                    this.photoDeleted = false;
                }, 250);
            },
            selectFileAway(files) {
                setTimeout(() => {
                    let queuedFiles = this.$refs.myVueDropzone_primary_away.getQueuedFiles();

                    if (queuedFiles.length > 1) {
                        this.failedmsg('You can only have one primary image');

                        files.forEach((value) => {
                            this.$refs.myVueDropzone_primary_away.removeFile(value);
                        });
                    }
                    this.away_shirt = queuedFiles[0];
                    this.photoDeletedAway = false;
                }, 250);
            } ,
            selectFileHome(files) {
                setTimeout(() => {
                    let queuedFiles = this.$refs.myVueDropzone_primary_home.getQueuedFiles();

                    if (queuedFiles.length > 1) {
                        this.failedmsg('You can only have one primary image');

                        files.forEach((value) => {
                            this.$refs.myVueDropzone_primary_home.removeFile(value);
                        });
                    }
                    this.home_shirt = queuedFiles[0];
                    this.photoDeletedHome = false;
                }, 250);
            }
        }
    };
</script>

<template>
    <div>
    <b-modal v-model="showModal" id="edit_sport_team" title="Edit Sport Team" title-class="font-18" centered size="lg"  @shown="getRelatedTeams" @hide="handleModalClose">
        <form @submit.prevent="updateSportTeam">
         <div class="row">
            <div class="form-group col-lg-8" style="font-weight: 500; margin-bottom: 10px">
                <div style=" margin-bottom: 3px;">Team ID : {{sportTeamData.team_id}} </div>
                <div  style=" margin-bottom: 3px;"> Team Name : {{sportTeamData.team_name_new}} </div>
                <div  style=" margin-bottom: 3px;">Team Code : {{sportTeamData.team_code_new}} </div>
                <div>Provider : {{sportTeamData.provider}} </div>
            </div>
         </div>
            <div class="form-group">
                <label for="team_name_overwrite">Team Name Overwrite:</label>
                <input type="text" id="team_id" class="form-control" style="display: none;" v-model="sportTeamData.team_id" />
                <input type="text" id="team_name_overwrite" class="form-control" v-model="sportTeamData.team_name" />
            </div>
            <div class="form-group">
                <label for="team_name_short">Team Code Overwrite:</label>
                <input type="text" id="team_name_short" class="form-control" v-model="sportTeamData.team_name_short" />
            </div>
            <div class="form-group">
                <label for="team_type">Team Type:</label>
                <input type="text" id="team_type" class="form-control" v-model="sportTeamData.team_type" />
            </div>
            <div class="form-group " >
                <label for="home_shirt">Home Shirt:</label>
                <template v-if="!photoDeletedHome && sportTeamData.home_shirt">
                    <b-form-group label-for="image" class="mb-3 text-center image-area">
                        <img :src="sportTeamData.home_shirt" alt="" width="200" class="img-thumbnail" />
                    </b-form-group>
                    <div class="text-center mt-3">
                        <a class="btn text-danger" v-b-tooltip.hover title="Delete Image" @click="deleteImageHome">
                            <i class="uil uil-trash-alt font-size-18"></i>
                        </a>
                    </div>
                </template>
                <template v-else>
                    <vue-dropzone
                            id="dropzone_primary_home_shirt"
                            ref="myVueDropzone_primary_home"
                            :use-custom-slot="true"
                            :options="dropzoneOptions_primary"
                            @vdropzone-files-added="selectFileHome"
                            no-progress-bar
                    >
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop file here or click to upload.</h4>
                        </div>
                    </vue-dropzone>
                </template>
            </div>
            <div class="form-group" >
                <label for="team_logo">Away Shirt:</label>
                <template v-if="!photoDeletedAway && sportTeamData.away_shirt">
                    <b-form-group label-for="image" class="mb-3 text-center image-area">
                        <img :src="sportTeamData.away_shirt" alt="" width="200" class="img-thumbnail" />
                    </b-form-group>
                    <div class="text-center mt-3">
                        <a class="btn text-danger" v-b-tooltip.hover title="Delete Image" @click="deleteImageAway">
                            <i class="uil uil-trash-alt font-size-18"></i>
                        </a>
                    </div>
                </template>
                <template v-else>
                    <vue-dropzone
                            id="dropzone_primary_away_shirt"
                            ref="myVueDropzone_primary_away"
                            :use-custom-slot="true"
                            :options="dropzoneOptions_primary"
                            @vdropzone-files-added="selectFileAway"
                            no-progress-bar
                    >
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop file here or click to upload.</h4>
                        </div>
                    </vue-dropzone>
                </template>
            </div>

            <div class="form-group">
                <label for="team_logo">Team Logo:</label>
                <template v-if="!photoDeleted && sportTeamData.team_logo">
                    <b-form-group label-for="image" class="mb-3 text-center image-area">
                        <img :src="sportTeamData.team_logo" alt="" width="200" class="img-thumbnail" />
                    </b-form-group>
                    <div class="text-center mt-3">
                        <a class="btn text-danger" v-b-tooltip.hover title="Delete Image" @click="deleteImage">
                            <i class="uil uil-trash-alt font-size-18"></i>
                        </a>
                    </div>
                </template>
                <template v-else>
                    <vue-dropzone
                            id="dropzone_primary"
                            ref="myVueDropzone_primary"
                            :use-custom-slot="true"
                            :options="dropzoneOptions_primary"
                            @vdropzone-files-added="selectFile"
                            no-progress-bar
                    >
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop file here or click to upload.</h4>
                        </div>
                    </vue-dropzone>
                </template>
            </div>
        </form>
        <template>
            <h4 style="margin-top:20px;">Related Teams</h4>
            <div class="row" v-for="(team, index) in relatedTeams" :key="index">
                    <div class="col-lg-4"  style="font-weight: 500;" >Team : {{ team.team_name }}</div>
                    <div class="col-lg-4"  style="font-weight: 500;" > {{ team.provider }}</div>
                <div class="col-lg-4">
                    <b-button
                            variant="danger"
                            @click="removeRelatedTeams(team.team_id,index)"
                    >
                        Delete
                    </b-button>
                </div>
            </div>
            <div class="form-group col-lg-12" style="padding: 0; margin-top: 15px;">
                <div v-if="!sportTeamData.team_name_short" class="col-lg-12">
                    *Please populate the team code overwrite to add related teams.
                </div>

                <b-button
                        variant="success"
                        v-b-modal.second_modal
                        :disabled="tryingToEdit || !sportTeamData.team_name_short"
                        :title="!sportTeamData.team_name_short ? 'Team name short must be populated to add a related team.' : ''"
                >
                    Add Related Team
                </b-button>

            </div>

        </template>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="updateSportTeam" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Save
            </b-button>
        </template>
    </b-modal>
        <b-modal
                id="second_modal"
                title="Add Related Team"
                title-class="font-18"
                centered
                hide-footer
                v-model="secondModalVisible"
                @hide="onSecondModalClose"
        >
            <div>
                <div class="col-sm-12 col-md-8">
                    <b-form-group label="Team name" label-for="formrow-team_name-input" class="mb-3">



                        <vue-bootstrap-typeahead
                                :key="team_name_key"
                                id="formrow-team_name-input"
                                ref="team_nameAutocomplete"
                                v-model="filterInput.team_name"
                                :data="TeamsData"
                                :serializer="s => s.text"
                                :multiple="true"
                                @hit="addSelectedTeam"
                                placeholder="Enter Related Team"
                        />

                    </b-form-group>
                </div>
                <div>
                    <h5>Selected Teams:</h5>
                    <ul>
                        <li v-for="(team, index) in selectedTeams" :key="index">
                            {{ team.text }}
                            <button class="btn btn-primary btn-sm" @click="removeSelectedTeam(team)">Remove</button>
                        </li>
                    </ul>
                </div>
                <div class="text-right mt-3">
                    <b-button variant="primary" @click="addRelatedTeam">
                        Add Related Team
                    </b-button>
                    <div class="card-body d-flex align-items-center justify-content-center" v-if="isLoading">
                          <b-spinner small></b-spinner>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

